<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap mt--150">
      <v-container>
            <h3 class="mb--100">ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ</h3>
            <v-row>
        <v-col xl="6"
               lg="6"
               md="6"
               sm="12"
               cols="12">
            <div class="single-service service__style--3 text-white mt--150 mb--50" >
                <div class="">
                  <p>1. Η κάρτα συλλογής πόντων AEGEAN PRIVILEGE CARD ανήκει κατά κυριότητα στην ΑΙΓΑΙΟΝ ΟΙΛ Α.Ε (εφεξής «ΑΙΓΑΙΟΝ»).
2. Ο πελάτης παραλαμβάνει αμέσως την κάρτα με τη συμπλήρωση και υπογραφή της σχετικής αίτησης. Ο κάτοχος μπορεί να χρησιμοποιήσει άμεσα στην κάρτα για συ-γκέντρωση πόντων. Οι πόντοι που αντιστοιχούν στην κάθε συναλλαγή θα συγκε-ντρώνονται στην κάρτα ηλεκτρονικά και θα είναι διαθέσιμοι προς εξαργύρωση από τη επόμενη ημέρα της συναλλαγής. Η πρώτη εξαργύρωση πόντων θα μπορεί να πραγματοποιηθεί εφόσον γίνει από την ΑΙΓΑΙΟΝ η ηλεκτρονική καταχώρηση των προσωπικών στοιχείων που έχει αναγράψει στην αίτησή του ο κάτοχος.
3. Ο κάτοχος οφείλει να γνωστοποιεί άμεσα (τηλεφωνικά 210-3006633 ή με email στο info@aegeanprivilegecard.gr) τυχόν αλλαγή των στοιχείων του για την αρτιότερη εκτέλεση των όρων της παρούσας και τη διατήρηση της επικοινωνίας και ενημέρω-σης του κατόχου.
4. Η κάρτα δεν χρησιμοποιείται για πληρωμές, δεν είναι πιστωτική και προορίζεται για αποκλειστική χρήση του κατόχου της.
5. Ο κάτοχος μπορεί να συναλλάσσεται πανελλαδικά με όλα τα πρατήρια Aegean που συμμετέχουν στο πρόγραμμα επιβράβευσης Aegean και έχουν την ειδική σήμανση και λογότυπο Aegean Privilege Card.
6. Ο κάτοχος της κάρτας πρέπει να έχει συμπληρώσει το 18ο έτος της ηλικίας του.
7. Οι πόντοι που δικαιούται ο κάτοχος της κάρτας σε κάθε του συναλλαγή θα προστί-θενται αμέσως στην κάρτα του εφόσον προσκομίσει στο πρατήριο την κάρτα του ή δώσει τον κωδικό της (ή το κινητό τηλέφωνο που είναι συνδεδεμένο με την κάρτα) και περαστούν συστημικά από τον υπάλληλο του πρατηρίου.
8. Οι πόντοι θα καταγράφονται ηλεκτρονικά μέσω της πλατφόρμας του κάθε πρατη-ρίου που είναι συνδεδεμένο στο πρόγραμμα επιβράβευσης. Σε κάθε κάρτα επιτρέ-πεται η καταχώρηση των πόντων μέχρι δύο (2) συναλλαγών ημερησίως. Η ΑΙΓΑΙΟΝ μπορεί να μεταβάλει το σύστημα αντιστοίχισης των πόντων ή/και να προσθέσει /αφαιρέσει προϊόντα /υπηρεσίες που συμμετέχουν στο πρόγραμμα επιβράβευσης μέσω των πρατηρίων της ή/και άλλων συνεργαζόμενων επιχειρήσεων, όποτε το κρίνει απαραίτητο.
9. Ο κάτοχος της κάρτας οφείλει να ελέγχει την ορθότητα και ακρίβεια των στοιχείων κάθε συναλλαγής του με τον πρατηριούχο. Η ΑΙΓΑΙΟΝ δεν φέρει καμία ευθύνη ή υπο-χρέωση για οποιοδήποτε θέμα εκπλήρωσης των υποχρεώσεων του πρατηριούχου, ούτε είναι υπόχρεη για οποιαδήποτε τυχόν αξίωση αποζημίωσης προς τον κάτοχο.
10. Σε περίπτωση που διαπιστωθεί ότι έχουν αποκτηθεί πόντοι εκτός της προαναφερό-μενης διαδικασίας και των όρων της παρούσας, η ΑΙΓΑΙΟΝ έχει το δικαίωμα να τους διαγράψει χωρίς άλλη προειδοποίηση.
11. Η κάρτα δεν μεταβιβάζεται και είναι στην ευθύνη του κατόχου της να την διατηρεί σε καλή κατάσταση συναλλαγής του με τον πρατηριούχο.
12. Ο κάτοχος πρέπει να ενημερώσει γραπτώς την ΑΙΓΑΙΟΝ σε περίπτωση κλοπής-απώ-λειας-καταστροφής της κάρτας του έχοντας την ευθύνη για όποια ζημιά προκύψει μέχρι την εκ μέρους του ειδοποίηση. Οι πόντοι του αυτόματα μεταφέρονται στην νέα κάρτα.
13. Σε περίπτωση που η κάρτα χαθεί ή καταστραφεί περισσότερες από τρείς (3) φο-ρές, θα αφαιρούνται από τον κάτοχο 150 πόντοι για κάθε επανέκδοση κάρτας που ζητηθεί και αυτό μέχρι και την έκτη (6) φορά, ενώ στην 7η αίτηση επανέκδοσης, η ΑΙΓΑΙΟΝ δικαιούται να αρνηθεί τη χορήγηση νέας κάρτας στον κάτοχο.
14. Τα δώρα εξαργυρώνονται μόνο με την συμπλήρωση των πόντων που έχει προκαθο-ρίσει η ΑΙΓΑΙΟΝ, βάσει του εκάστοτε ισχύοντος καταλόγου δώρων. Κάθε εξαργύρω-ση αντιστοιχεί με αφαίρεση ισάριθμων πόντων.
15. Η ΑΙΓΑΙΟΝ μπορεί να ανανεώνει ή να τροποποιεί τον κατάλογο των δώρων του προ-γράμματος, όποτε επιλέξει. Ο κατάλογος θα γνωστοποιείται ηλεκτρονικά μέσω της εφαρμογής, επίσης θα αναρτάται στο www.aegeanprivilegecard.gr</p>
                </div>
            </div>
        </v-col>
       <v-col xl="6"
               lg="6"
               md="6"
               sm="12"
               cols="12">
            <div class="single-service service__style--3 text-white mt--150 mb--50">
                <p>16. Δεν υπάρχει η δυνατότητα απόκτησης ακριβότερου δώρου από αυτό που αντιστοι-χεί στους πόντους του με την καταβολή της χρηματικής διαφοράς. Επιπλέον ο κάτο-χος δεν μπορεί να προμηθευτεί πόντους με την καταβολή μετρητών ή άλλου μέσου συναλλαγής.
17. Οι διαδικασίες παράδοσης των δώρων ορίζονται από την ΑΙΓΑΙΟΝ και γνωστο-ποιούνται ηλεκτρονικά μέσω της εφαρμογής, επίσης θα αναρτώνται στο www.aegeanprivilegecard.gr. Ο κάτοχος κάρτας προκειμένου να εξαργυρώσει το δώρο του θα πρέπει να καλέσει το τμήμα εξυπηρέτησης στο τηλ. 2013006633 καθημερινά 10.00-18.00 εκτός Σαββάτου, Κυριακής και αργιών. Ο κάτοχος θα πρέπει να πιστο-ποιήσει τα στοιχεία του, εφόσον ζητηθεί από την ΑΙΓΑΙΟΝ για την παράδοση των δώρων.
18. Ο χρήστης της εφαρμογής μπορεί να κάνει ηλεκτρονικά την εξαργύρωση. Σε πε-ρίπτωση μη διαθεσιμότητας κάποιων εκ των εξαργύρωσης δώρων ο κάτοχος θα πρέπει να εξαργυρώσει τους πόντους με άλλο παρόμοιο δώρο ή ανάλογης αξίας.
19. Τα δώρα από τον κατάλογο δώρων που έχουν παραγγελθεί από τον κάτοχο, σε καμία περίπτωση δεν θα μπορούν να απορριφθούν ή να επιστραφούν εκ των υστέρων.
20. Η ΑΙΓΑΙΟΝ δικαιούται να ακυρώσει πόντους ή/και την κάρτα ενός κατόχου σε περί-πτωση που αυτός δεν έχει πραγματοποιήσει καμία συναλλαγή τους τελευταίους 12 μήνες.
21. Ο κάτοχος υποχρεούται να εξαργυρώσει τους πόντους του εντός τριών (3) ετών, διαφορετικά ακυρώνονται.
22. Ο κάτοχος της κάρτας δεν θα δικαιούται να προβεί στην εξαργύρωση των πόντων του για όσο χρονικό διάστημα διατηρεί ληξιπρόθεσμες οφειλές προς την ΑΙΓΑΙΟΝ από οποιαδήποτε συναλλαγή.
23. Αυτό το πρόγραμμα επιβράβευσης δεν ισχύει σε περίπτωση απαγόρευσης ή περιο-ρισμού του κατόχου από τον Νόμο. Η ΑΙΓΑΙΟΝ δικαιούται να καταγγείλει μονομερώς την παρούσα και να ακυρώσει την κάρτα σε περίπτωση παράβασης οποιουδήποτε όρου της παρούσας από τον κάτοχο, που ορίζονται όλοι ως ουσιώδεις ή αν συντρέ-ξει άλλος σπουδαίος λόγος.
24. Η κατοχή και η χρήση της κάρτας σημαίνει αποδοχή των όρων για τους οποίους ο κάτοχος έχει λάβει πλήρη γνώση και περιγράφονται στην εφαρμογή. Στην περί-πτωση απόκτησης ηλεκτρονικής κάρτας μέσω της εφαρμογής Aegean ισχύουν οι παραπάνω όροι και χρησιμοποιείται ο αριθμός της κάρτας που έχει αποκτήσει ο χρήστης μέσω της εφαρμογής.
25. Ο κάτοχος για θέματα που αφορούν στο πρόγραμμα μπορεί να επικοινωνεί με το τμήμα εξυπηρέτησης είτε τηλεφωνικά στο 210-3006633 καθημερινά 10.00-18.00 εκτός Σαββάτου, Κυριακής και αργιών, ή εγγράφως με email στο info@aegeanprivilegecard.gr.
26. Ο κάτοχος της κάρτας εξουσιοδοτεί με την παρούσα σύμβαση την ΑΙΓΑΙΟΝ να επε-ξεργάζεται τα δηλωθέντα στοιχεία επικοινωνίας του με σκοπό την εφαρμογή, εκτέ-λεση, ασφάλεια του προγράμματος, βελτιστοποίησης των παρεχόμενων προϊόντων υπηρεσιών της ΑΙΓΑΙΟΝ ΟΙΛ ΑΕ και ενημέρωσης του κατόχου. Ο κάτοχος δικαιούται να ζητήσει οποτεδήποτε τη διαγραφή των προσωπικών του δεδομένων, οπότε και υποχρεούται στην άμεση επιστροφή της κάρτας του προς την ΑΙΓΑΙΟΝ.
27. Ο κάτοχος της κάρτας συμφωνεί και αποδέχεται ότι η ΑΙΓΑΙΟΝ θα διατηρεί σε αρχείο και θα επεξεργάζεται τα Προσωπικά Δεδομένα του με σκοπό την διασφά-λιση της σωστής λειτουργίας και εκτέλεση του προγράμματος και την βελτίωση των υπηρεσιών και των διαδικασιών του προγράμματος, την υποστήριξη της συναλλακτικής σχέσης κατόχου και εταιρίας και τη συνεχή ενημέρωση του κα-τόχου για τις υπηρεσίες, προϊόντα, διαγωνισμούς και προσφορές της ΑΙΓΑΙΟΝ. Ο κάτοχος μπορεί να ενημερωθεί αναλυτικά για την πολιτική της ΑΙΓΑΙΟΝ περί προστασίας των προσωπικών δεδομένων του κατόχου στο www.aegeanprivilegecard.gr</p>
                
            </div>
        </v-col>       
    </v-row>
            <p></p>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {        
        
      };
    },
  };
</script>
